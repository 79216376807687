<template>
	<div class="forgot-password pt-4">
		<h3>{{$t('forgotPassword.forgotPassword')}}</h3>
		<form-container >
			<p class="col-12">{{$t('forgotPassword.helpText')}}</p>
			<field-input v-model="email" classes="col-md-12" :error="emailError" :label="$t('labels.email')" />
			<div class="col-12 pt-2">
				<standard-button :loader-active="loading" :text="$t('forgotPassword.sendEmail')" @click.native="attemptSendPasswordResetEmail" />
			</div>
		</form-container>
	</div>
</template>

<script>
	import FormContainer from "@/components/container/FormContainer";
	import FieldInput from "@/components/inputs/FieldInput";
	import StandardButton from "@/components/buttons/StandardButton";
	import {simpleEmailValidation} from "@/helperFunctions";
	export default {
		name: "ForgotPassword",
		components: {StandardButton, FieldInput, FormContainer},
		data(){
			return {
				email: '',
				emailError: '',
				loading: false
			}
		},
		methods: {
			attemptSendPasswordResetEmail(){
				if(this.loading === false)
				{
					this.emailError = '';
					if(simpleEmailValidation(this.email) !== false)
					{
						this.loading = true;
						this.$concreteApi.post(undefined,'/account/trigger-password-reset',{email:this.email},true)
						.then(response => {
							if(typeof response.sent !== 'undefined' && response.sent === true)
							{
								this.$swal.fire({
									icon: 'success',
									title: this.$t("terms.success"),
									text: this.$t("forgotPassword.emailHasBeenSent"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((result) => {
									this.$router.push({name:'login',params:{lang: this.$i18n.locale}});
								});
							}
							else
							{
								if(typeof response.reason !== 'undefined')
								{
									this.$swal.fire({
										icon: 'error',
										title: this.$t("terms.error"),
										text: this.$t("errors."+response.reason),
										confirmButtonText: this.$t("terms.ok"),
										confirmButtonColor: "#495760"
									});
								}
								else
								{
									this.fireGenericError();
								}
							}
						})
						.catch(error => {
							console.log(error)
							this.fireGenericError();
						})
						.then(() => {
							this.loading = false;
						},() => {
							this.loading = false;
						})
					}
					else
					{
						this.emailError = this.$t('errors.notAnEmail');
					}
				}
			}
		}
	};
</script>

<style scoped>

</style>
